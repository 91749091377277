import React from 'react';
import { graphql } from 'gatsby';
import { Modal, ModalContent, ModalTrigger } from '../modal';
import DatoAsset from '../asset';
import { colors } from '../colors';
import { StyledButtonAsLink } from '../styled-button';
import { getLink } from '../link';
import { breakpoints } from '../tokens';
import { trackDemoVideoPlayed, trackSecondaryCtaHeroClick } from '../../../util/analytics';
import { HeroContext } from '../../structured-text/blocks/hero/content';

export type VideoPopoverButtonProps = Omit<Queries.VideoPopoverButtonPropsFragment, 'title'> & { children: React.ReactNode | string, showPlayButton?: boolean };

export const VideoContext = React.createContext(false);

const VideoPopover = ({ children, video, link, showPlayButton = false, onOpenChange }: VideoPopoverButtonProps): JSX.Element => {
    const [openState, setOpenState] = React.useState(false);
    const isWithinHero = React.useContext(HeroContext);

    if (!video || (!video?.[0]?.video && !video?.[0]?.externalVideo)) {
        return (
            <>
                {children}
            </>
        );
    }

    return (
        <Modal
            open={openState}
            onOpenChange={(e) => {
                if (onOpenChange) {
                    onOpenChange(e);
                }
                setOpenState(e);
            }}
        >
            <ModalTrigger
                asChild
                onClick={() => {
                    if (isWithinHero) {
                        trackSecondaryCtaHeroClick();
                    }
                }}
            >
                {showPlayButton ? (
                    <div
                        role="button"
                        css={{
                            width: '100%',
                            height: '100%',
                            position: 'relative',
                            cursor: 'pointer',
                        }}
                        aria-roledescription="Open Video Popup"
                    >
                        {children}
                        <svg
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            css={{
                                fill: colors.shadesWhite,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                height: '55px',
                            }}
                        >
                            <path d="m6.73 20.93 14.05-8.54a.46.46 0 0 0 0-.78L6.73 3.07a.48.48 0 0 0-.73.39v17.07a.48.48 0 0 0 .73.4Z" />
                        </svg>
                    </div>
                ) : children}
            </ModalTrigger>
            <ModalContent css={{
                width: '80vw',
                padding: '0',
                height: 'unset',
                maxHeight: 'unset',
                backgroundColor: 'transparent',
                maxWidth: '120vh',
            }}
            >
                <div css={{
                    backgroundColor: colors.shadesBlack,
                    minHeight: '40vh',
                }}
                >
                    <DatoAsset
                        asset={video?.[0]?.video || video?.[0]?.externalVideo}
                        fromModal
                        onPlayStateChange={(state) => {
                            if (state === 'play') {
                                trackDemoVideoPlayed();
                            }
                        }}
                        onExitFullscreen={() => {
                            setOpenState(false);
                        }}
                    />
                </div>
                {link && link.length > 0 && (
                    <div css={{
                        position: 'absolute',
                        bottom: '28px',
                        right: '28px',
                        [breakpoints.mb]: {
                            position: 'relative',
                            right: '0',
                            top: '20px',
                        },
                    }}
                    >
                        <VideoContext.Provider value>
                            <StyledButtonAsLink to={getLink(link[0].link)}>
                                {link[0].title}
                            </StyledButtonAsLink>
                        </VideoContext.Provider>
                    </div>
                )}
            </ModalContent>
        </Modal>
    );
};

export default VideoPopover;

export const query = graphql`
    fragment VideoPopoverButtonProps on DatoCmsVideoPopoverButton {
        title
        __typename
        video {
            ... on DatoCmsVideo {
                video {
                    ...DatoCmsInternalAsset
                }
            }
            ... on DatoCmsExternalVideo {
                externalVideo {
                    ...DatoCmsExternalAsset
                }
            }
        }
        link {
            ...LinkWithTitleProps
        }
    }
`;
